// styles.scss
@import "@progress/kendo-theme-core/scss/functions/index.import.scss";
@import "@progress/kendo-theme-default/scss/core/_index.scss";
// $kendo-colors: (
// );
$kendo-colors: k-map-merge($kendo-colors,
    k-generate-color-variations("primary", #009ddc, "default"));

$kendo-color-primary: #009ddc; // Define primary color properly
$border-radius: 2px;
$primary: #009ddc;
$kendo-color-primary:#009ddc;
$secondary: #f6f6f6;
$info: #0e87c9;
$success: #1ba397;
$warning: #fec240;
$error: #f2635c;
$body-text: #424242;
$body-bg: #ffffff;
$headings-text: #272727;
$subtle-text: #666666;
$disabled-text: #8f8f8f;
$component-text: #424242;
$component-bg: #ffffff;
$base-text: #424242;
$base-bg: #fafafa;
$hovered-text: #424242;
$hovered-bg: #ececec;
$selected-text: #ffffff;
$selected-bg: #23bde0;
$button-text: #424242;
$button-bg: #f5f5f5;
$link-text: #23bde0;
$link-hover-text: #1d9fbc;
$series-a: #f79321;
$series-b: #1ba397;
$series-c: #fec240;
$series-d: #5090cd;
$series-e: #009ddc;
$series-f: #f2635c;

@import "@progress/kendo-theme-default/dist/all.scss";

